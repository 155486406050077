<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>카지노&Slot</sub-title>
                <div class="main_panel">
                    <div class="casino_panel">
                        <div class="casino_re_panel">
                            <div class="p1">
                                <i class="fa fa-dot-circle-o" style="color: #bc955b"></i> 카지노 충전
                            </div>
                            <div class="p2">
                                <div class="t1">금액</div>
                                <div class="t2">
                                    <input type="number" v-model="casinocash" placeholder="금액">
                                </div>
                                <div class="t3">
                                    <button type="button" class="btn-all" @click="allMoney()">전체</button>
                                    <button type="button" class="btn-reset" @click="changeCasinoMoney(0)">초기화</button>
                                </div>
                            </div>
                            <div class="p3">
                                <button class="btn-change" @click="change2casinocash()">카지노머니 로 전환</button>
                            </div>
                        </div>
                        <div class="casino_re_panel">
                            <div class="p1">
                                <i class="fa fa-dot-circle-o" style="color: #bc955b"></i> 카지노 환전
                            </div>
                            <div class="p2" style="text-align: center">
                                <span style="width: 100%;height: 28px;line-height: 28px">현재 카지노에 보유한 금액 전체를 전환합니다</span>
                            </div>
                            <div class="p3">
                                <button class="btn-change" @click="change2sportsmoney()">스포츠머니 로 전환</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="cs_panel_button">
                    <button class="btn-casino" :class="{'btn-cs-active':type===1}"
                            @click="change(1)">카지노
                    </button>
                    <button class="btn-slot" :class="{'btn-cs-active':type===2}"
                            @click="change(2)">슬롯
                    </button>
                </div>

                <div class="casino_panel" v-if="type===1">
                    <div class="casino">
                        <div @click="openCasinoGame(45)">
                            <img src="../../assets/images/casino/01.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(1)">
                          <img src="../../assets/images/casino/01.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(5)">
                          <img src="../../assets/images/casino/01.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(9)">
                          <img src="../../assets/images/casino/01.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(28)">
                          <img src="../../assets/images/casino/01.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(44)">
                          <img src="../../assets/images/casino/01.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(21)">
                          <img src="../../assets/images/casino/01.jpg" alt="">
                        </div>
                        <div @click="openCasinoGame(16)">
                          <img src="../../assets/images/casino/01.jpg" alt="">
                        </div>
                    </div>

                </div>
                <div class="casino_panel" v-if="type === 2">
                    <div class="slot-btns">
                        <button class="btn-slot-comp bw4" v-for="(item,index) in slotCompanies"
                                @click="getGameListByCode(item.code)">
                            {{item.name}}
                        </button>
                    </div>
                    <div class="search">
                        <input type="text" v-model="searchVal" @input="search" placeholder="슬롯 2글자 이상 검색">
                    </div>
                    <div class="slot_game">
                        <div class="slot-item" v-for="(item,index) in slotGameList" :key="index"
                             @click="openSlotGame(item.id)" v-if="item.isshow">
                            <img :src="item.img" alt="">
                            <span class="name">{{item.tKR}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <!--위로가기-->
        <scroll-top-comp></scroll-top-comp>
        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import ScrollTopComp from "../../components/ScrollTopComp";
    import SubTitle from "../../components/SubTitle";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {
        addMemberCasinoCash,
        getAccountBalance, getCasinoMaintaining,
        getGameList,
        getGameUrl,
        getLobbyUrl, subjectMemberCasinoCash
    } from "../../network/casinoRequest";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";

    export default {
        name: "CasinoSlotGame",
        mixins: [postionMixin],
        components: {
            RightButtonsComp,
            UserInfoComp,
            SportsBetCartComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            FootComp, TopbarComp, SubTitle, ScrollTopComp, LeisureLeftComp, LeftBarComp, RightBarComp
        },
        data() {
            return {
                slotCompanies: [
                    {'code': '8', 'name': '프로그매틱'},
                    {'code': '19', 'name': 'RTG'},
                    {'code': '11', 'name': '스토크랏'},
                    {'code': '23', 'name': 'CQ9'},
                    {'code': '38', 'name': 'B게이밍'},
                    {'code': '44', 'name': 'AG'},
                    {'code': '15', 'name': 'BBIN'},
                    {'code': '40', 'name': '에보플레이'},
                ],
                currentSlotCode: '8',
                slotGameList: [],
                sportsConst,
                casinocash: 0,
                position: "Casino",
                searchVal: '',
                type: 1,
            }
        },
        methods: {
            change(type) {
                this.type = type
            },
            search() {
                if (this.searchVal === '') {
                    this.slotGameList.forEach(g => {
                        this.$set(g, 'isshow', true)
                    });
                } else {

                    this.slotGameList.forEach(g => {
                        if (g.tKR.indexOf(this.searchVal) !== -1) {
                            this.$set(g, 'isshow', true)
                        } else {
                            this.$set(g, 'isshow', false)
                        }

                    });
                }

                console.log()
            },
            change2sportsmoney() {
                this.$swal({
                    title: '스포츠머니로 전환 하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        subjectMemberCasinoCash().then((res) => {
                            if (res.data.success) {
                                this.$swal({
                                    title: '전환성공',
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.casinocash = 0;
                                this.$store.dispatch('actionUserInfo')
                                setTimeout(() => {
                                    this.getCasinoBalance();
                                }, 1300)
                            } else {
                                this.$swal({
                                    title: '전환실패, ' + res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }

                        })
                    }
                })

            },
            getCasinoBalance() {
                getAccountBalance().then(res => {
                    this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                })
            },
            changeCasinoMoney(cash) {
                if (cash === 0) {
                    this.casinocash = 0;
                } else {
                    this.casinocash += parseInt(cash);
                }
            },
            change2casinocash() {
                if (this.casinocash > this.$store.state.userInfo.cash) {
                    this.$swal({
                        title: '보유금액 ' + this.$store.state.userInfo.cash + '원을 초과하였습니다',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                addMemberCasinoCash(this.casinocash).then(res => {
                    if (res.data.success) {
                        this.$swal({
                            title: '전환성공',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.casinocash = 0;
                        this.$store.dispatch('actionUserInfo')
                        setTimeout(() => {
                            this.getCasinoBalance();
                        }, 1300)

                    } else {
                        this.$swal({
                            title: '전환실패, ' + res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            openCasinoGame(thirdPartyCode) {
                getLobbyUrl(thirdPartyCode).then((res) => {
                    if (res.data.success) {
                        let url = res.data.data;
                        window.open(url, '_blank')
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            openSlotGame(gameId) {
                getGameUrl(gameId).then(res => {
                    if (res.data.success) {
                        let url = res.data.data;
                        window.open(url, '_blank')
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            getGameListByCode(thirdPartyCode) {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getGameList(thirdPartyCode).then(res => {
                    if (res.data.success) {
                        this.slotGameList = JSON.parse(res.data.data).games;
                        this.slotGameList.forEach(g => {
                            this.$set(g, 'isshow', true)
                        });
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                });
            },
            allMoney() {
                this.casinocash = this.$store.state.userInfo.cash

            }
        },
        created() {
            if (this.$store.state.isLogin) {
                getCasinoMaintaining().then(res => {
                    if (res.data.success) {
                        if (!res.data.data) {
                            this.$swal({
                                title: '카지노 점검중입니다',
                                type: 'error',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                        } else {
                            this.getGameListByCode(this.currentSlotCode);
                            if (this.$store.state.isLogin) {
                                this.getCasinoBalance()
                            }
                        }
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }

                })
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
    .casino_panel {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 16px;
    }

    .casino_panel .casino_re_panel {
        width: 49.6%;
        height: 160px;
        box-sizing: border-box;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: var(--noticeBg);
    }

    .casino_panel .casino_re_panel .p1 {
        width: 100%;
        text-align: center;
        padding: 10px 0;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
    }

    .casino_panel .casino_re_panel .p2 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
        text-align: center;
        padding: 10px 0;
        color: #fff9ff;
        font-size: 14px;
        font-weight: 600;
    }

    .casino_panel .casino_re_panel .p2 .t1 {
        text-align: center;
        width: 30%;
    }

    .casino_panel .casino_re_panel .p2 .t2 {
        text-align: center;
        width: 40%;
    }

    .t2 input {
        width: 100%;
        text-align: center;
        background-color: #ffffff;
        height: 28px;
        color: #0b1423;
    }

    .casino_panel .casino_re_panel .p2 .t3 {
        text-align: center;
        width: 30%;
    }

    .casino_panel .casino_re_panel .p2 .t3 button {
        height: 28px;
        padding: 0 3px;
        border-radius: 2px;
    }

    .casino_panel .casino_re_panel .p2 .btn-all {
        border: 2px solid var(--btn01Bg);
    }

    .casino_panel .casino_re_panel .p2 .btn-reset {
        background-color: var(--btn01Bg);
        color: #ffffff;
        margin-left: 5px;
    }

    .casino_panel .casino_re_panel .p3 {
        text-align: center;
    }

    .casino_panel .casino_re_panel .btn-change {
        height: 36px;
        padding: 0 16px;
        border-radius: 2px;
        color: #ffffff;
        font-weight: 600;
    }
    .casino_panel .casino_re_panel .cbg1{
        background: linear-gradient(180deg, #fffd4f, #fffd70) !important;
    }
    .casino_panel .casino_re_panel .cbg2{
        background: linear-gradient(180deg, #ac7eff, #c09eff) !important;
    }

    .casino_panel .slot-btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 5px;
    }

    .casino_panel .btn-slot-comp {
        background-color: var(--btn01Bg);
        margin-bottom: 2px;
        padding: 10px;
        border-radius: 3px;
        color: #fff9ff;
        font-weight: bold;

    }
    .btn-cs-active{
        color: #fff9ff!important;
        background-color: var(--sportsKindButtonBgActive1) !important;
    }

    .casino_panel .search {
        width: 100%;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .casino_panel .search input {
        color: #0c0e0e;
        background-color: #cfcfd1;
        height: 32px;
        text-align: center;
    }

    .cs_panel_button {
        margin: 20px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cs_panel_button button {

    }

    .btn-casino {
        border: 2px solid var(--loginBtnBg);
        border-radius: 3px;
        padding: 10px 0;
        width: 50%;
        margin: 0 5px;
    }

    .btn-slot {
        border: 2px solid var(--loginBtnBg);
        border-radius: 3px;
        padding: 10px 0;
        width: 50%;
        margin: 0 5px;
    }

    @media screen and (max-width: 1024px) {
        .casino_panel .casino_re_panel {
            width: 100%;
            margin-bottom: 16px;
        }
    }

    .button_group a {
        margin-top: 3px;
    }

    .casino_panel .casino {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        cursor: pointer;
    }

    .casino_panel .casino div {
        width: 19.5%;
        box-sizing: border-box;
        padding: 10px 10px;
        text-align: center;
        min-height: 320px!important;
    }


    .casino img {
        width: 100%;
        --border: 1px solid var(--loginBtnBg);
        border-radius: 4px;

    }

    .casino_panel .slot_company {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        text-align: center;
        margin: 10px auto;
    }


    .casino_panel .slot_game {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        margin-top: 10px;
    }

    .casino_panel .slot_game .slot-item {
        min-width: 150px;
        max-width: 200px;
        border-bottom: 1px solid #4a4a4a;
        cursor: pointer;
        margin: 5px 5px;
    }

    .casino_panel .slot_game .name {
        display: block;
        line-height: 50px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #fff9ff;
    }

    .casino_panel .slot_game img {
        width: 100%;
        border: 1px solid #f4c600;
        border-radius: 5px;
    }

    @media screen and (max-width: 800px) {
        .casino div {
            width: 100% !important;
            box-sizing: border-box;
            padding: 10px 10px;
        }

        .casino_panel .slot_game div {
            width: 45.5%;
        }

        .casino_panel .slot_company a {
            width: 48% !important;
        }

        .casino_panel .board_box {
            width: 100% !important;
        }

    }
</style>